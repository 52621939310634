import { getAuth, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { useSignInWithGoogle } from "react-firebase-hooks/auth";
import { addDoc, collection, limit, orderBy, query, serverTimestamp } from "firebase/firestore";
import { updateProfile } from "firebase/auth";
import { useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import "./App.css";
import { app, databaseApp } from "./services/firebaseConfig";
import React, { useEffect } from "react";

const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export const App = () => {
  const [user] = useAuthState(auth);
  return (
    <div className='App'>
      <header>
        <h2>Chat ao Vivo</h2>
        <SignOut />
      </header>
      <section>{user ? <ChatRoom /> : <SignIn />}</section>
    </div>
  );
};

export const ChatRoom = () => {
  const dummy = useRef()
  const messagesRef = collection(databaseApp, "messages");
  const q = query(messagesRef, orderBy("createdAt"), limit(25));
  const [messages] = useCollectionData(q, { idField: "id" });
  const [formValue, setFormValue] = useState("");
  const sendMessage = async (e) => {
    e.preventDefault();
    const { photoURL, uid, displayName } = auth.currentUser;

    await addDoc(messagesRef, {
      text: formValue,
      uid,
      photoURL,
      displayName,
      createdAt: serverTimestamp()
    });
    setFormValue('')
    dummy.current.scrollIntoView({behavior: 'smooth'})
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();
      console.log('Current Time:', currentTime);
      if (currentTime.getHours() === 11 && currentTime.getMinutes() === 58) {
        console.log('Deleting messages...');
        const messagesRef = collection(databaseApp, "messages");
        messagesRef.get().then(snapshot => {
          console.log('Snapshot:', snapshot);
          snapshot.forEach(doc => {
            console.log('Deleting document:', doc.id);
            doc.ref.delete();
          });
        }).catch(error => {
          console.error('Error deleting messages:', error);
        });
      }
    }, 1000 * 60); // check every minute
    return () => clearInterval(interval);
  }, []);  

  return (
    <>
      <div className="scroll">
        {messages &&
          messages.map((msg, index) => <ChatMessage key={index} message={msg} />)}
          <div ref={dummy}></div>
      </div>
      <form onSubmit={sendMessage}>
        <input
        placeholder="Envie sua mensagem"
          type="text"
          value={formValue}
          onChange={(e) => setFormValue(e.target.value)}
        />
        <button type="submit"  disabled={!formValue}>Enviar</button>
      </form>
    </>
  );
};

export const ChatMessage = (props) => {
  const { text, uid, photoURL, displayName, id } = props.message;

  const messagesRef = collection(databaseApp, "messages");

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';

  return (
      <div className={`${messageClass} message`}>
        <img className="profilepic" src={photoURL || 'https://api.adorable.io/avatars/23/abott@adorable.png'} />
        <p className="conteudo-mensagem">{text}</p>
        <div className="username">{displayName || "Anônimo"}</div>
      </div>
    );
};


export const SignIn = () => {
  const [signInWithGoogle, user, loading, error] = useSignInWithGoogle(auth);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [showCreateAccountForm, setShowCreateAccountForm] = useState(false);

  const handleCreateAccount = async () => {
    try {
      const credential = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(auth.currentUser, {
        displayName: username
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSignInWithEmailAndPassword = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="sign-in-container">
      <button className="sign-in" onClick={() => signInWithGoogle()}>
        Acessar com o Google
      </button>
      {!showCreateAccountForm && (
        <button onClick={() => setShowCreateAccountForm(true)}>Criar uma conta</button>
      )}
      {showCreateAccountForm && (
        <div className="form-conta">
          <form onSubmit={handleSignInWithEmailAndPassword}>
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Seu melhor email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
            <label htmlFor="password">Senha:</label>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="Senha com 6 caracteres"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <label htmlFor="username">Nome de usuário:</label>
            <input
              type="text"
              id="username"
              name="username"
              placeholder="Seu nome de usuário"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
            />
          </form>
          <button onClick={handleCreateAccount}>Criar conta</button>
            <button onClick={() => setShowCreateAccountForm(false)}>Cancelar</button>
        </div>
      )}
    </div>
  );
};



export const SignOut = () => {
  return (
      auth.currentUser && (
      <button className="sign-out" onClick={() => auth.signOut()}>
      Sair
    </button>
    )
  );
};